import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { getShareLinks } from '@lp-root/src/components/mol.share-icons-cell/share-icons-cell.utils';
import { useMobile } from '@web/atomic/obj.custom-hooks/mobile.hook';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { getCanonicalUrl } from '@web/utils/url';
import { Body, Col, GrayColor, LightColor, FaIcon, Grid, H1, Hbox, Row } from '@web/atomic';
import { TextAreaField } from '@web/atomic/legacy/atm.text-field';
import { hasNavigator } from '@web/utils/platform';
import { GatsbyButton } from '@components/atm.button/button.component';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import { PageProps } from '../../utils/local-types';

const SharePage: React.FunctionComponent<PageProps> = (props) => {
  const mobile = useMobile();
  const text = `Se você gostaria de melhorar seu sono, utilize a Vigilantes do Sono! É um programa online e sem remédios!`;
  const url = `https://www.vigilantesdosono.com`;
  const [textToShare, setTextToShare] = useState(text);
  const onValueChange = (str: string) => {
    setTextToShare(str);
  };

  const shareLinks = getShareLinks({ url, title: 'Vigilantes do Sono', text: textToShare });

  const isNativeShareEnabled = hasNavigator() && (navigator as any).share;
  const onNativeShareClick = () => {
    const nav: any = navigator;
    // https://web.dev/web-share/
    if (nav.share) {
      nav.share(shareLinks.raw);
    } else {
      console.error('nav.share not available');
    }
  };

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: `Compartilhe a vigilantes do sono`,
          description: '',
        }}
      />

      <Grid fluid>
        <Row center="xs" mt mb>
          <Col xs={12} sm={8} md={6}>
            <H1>Compartilhe a Vigilantes do Sono</H1>
            <Body>Ajude mais pessoas a superarem também a insônia.</Body>
            <Body>Abaixo está o texto que será compartilhado, mas fique a vontade para personalizar como quiser.</Body>
            <TextAreaField value={textToShare} onValueChange={onValueChange} />
          </Col>
        </Row>

        <Row center="xs" mt mb>
          <Col xs={12} sm={8} md={6}>
            <Hbox>
              <Hbox.Item hAlign="center">
                <GatsbyButton kind="primary" to={shareLinks.email} aria-label="email share" external target="_blank">
                  <FaIcon.Envelope size="lg" color={GrayColor.White} />
                </GatsbyButton>
              </Hbox.Item>
              {mobile && (
                <Hbox.Item hAlign="center">
                  <GatsbyButton kind="primary" to={shareLinks.messenger} aria-label="messenger share" external target="_blank">
                    <FaIcon.Messenger size="lg" color={GrayColor.White} />
                  </GatsbyButton>
                </Hbox.Item>
              )}
              <Hbox.Item hAlign="center">
                <GatsbyButton kind="primary" to={shareLinks.linkedin} aria-label="linkedin share" external target="_blank">
                  <FaIcon.Linkedin size="lg" color={GrayColor.White} />
                </GatsbyButton>
              </Hbox.Item>
              <Hbox.Item hAlign="center">
                <GatsbyButton kind="primary" to={shareLinks.twitter} aria-label="twitter share" external target="_blank">
                  <FaIcon.Twitter size="lg" color={GrayColor.White} />
                </GatsbyButton>
              </Hbox.Item>
              {isNativeShareEnabled && (
                <Hbox.Item hAlign="center" external>
                  <GatsbyButton kind="primary" onClick={onNativeShareClick} aria-label=" share">
                    <FaIcon.Share size="lg" color={GrayColor.White} />
                  </GatsbyButton>
                </Hbox.Item>
              )}
            </Hbox>
          </Col>
        </Row>

        <LargeSeparatorRow />
      </Grid>
    </WebviewLayout>
  );
};

export default SharePage;

export const query = graphql`
  query ShareQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
